/*** Add your styles here **/
@font-face {
    font-family: ProximaNova-bold;
    src: url(/webfonts/ProximaNova-Bold.otf);
  }
  @font-face {
    font-family: ProximaNova-thin;
    src: url(/webfonts/ProximaNova-Thin.otf);
  }
  @font-face {
    font-family: ProximaNova;
    src: url(/webfonts/ProximaNova-Regular.otf);
  }
  @font-face {
    font-family: ProximaNova-extra-bold;
    src: url(/webfonts/ProximaNovaExtrabold.otf);
  }

  
  body{
    margin: 0 auto;
     font-family: ProximaNova-extra-bold;
      font-family: ProximaNova-bold;
      font-family: ProximaNova-thin;
      font-family: ProximaNova;
      text-transform: 'none' !important;
  }
  
  body,html {
    height: 100%;
    text-transform: 'none' !important;
  }
  ul{
    margin: 0;
    padding: 0;
  }
  li{
    list-style: none;
  }
  a{
    text-decoration: none;
  }
  a:hover, a:focus, a:active{
    text-decoration: none;
    border: none;
    outline: none;
  }
  button:active, button:focus{
    border: none;
    outline: none;
  }
  img{
    max-width: 100%;
  }
  p {
      color: #9f9f9f;
  }
  h6 {
    text-transform: 'none' !important;
}
  
  .modal-backdrop {z-index: 10;}
  .modal-content {z-index: 20;}
  /*header*/
  
  .header-inner {
    display: flex;
  }
  .logo {
    max-width: 150px;
  }
  .main-menu-wrapper {
    width: 100%;
    text-align: right;
  }
  .collapse-nav li {
    display: inline-block;
  }
  .menu {
    display: inline-block;
  }
  .social-menu {
    display: inline-block;
  }
  
  .menu li a {
    display: block;
    color: #000;
    font-size: 18px;
    font-weight: bold;
    margin-right: 45px;
    position: relative;
    transition: .3s;
    padding-bottom: 10px;
  }
  .menu li a::after {
    content: "";
    width: 0;
    height: 4px;
    background: #02D298;
    position: absolute;
    bottom: 0;
    left: 0;
    transition: .3s;
  }
  .menu li a:hover::after {
    width: 100%;
  }
  .social-menu {
    /* margin-left: 70px; */
  }
  .social-menu li a {
    display: block;
    width: 29px;
    height: 29px;
    line-height: 29px;
    text-align: center;
    background: #01D298;
    color: #FFF;
    border-radius: 50%;
    font-size: 16px;
    margin: 0;
    margin-right: 13px;
    transition: .3s;
    border: 1px solid #01D298;
  }
  .social-menu li:last-child a {
    margin-right: 0;
  }
  .menu li:last-child a {
    margin-right: 0;
  }
  .social-menu li a:hover {
    background: #FFF;
    color: #01D298;
  }

  a:hover {
    color: #01D298 !important;
    text-decoration: underline;
}

.mySocialLogon .MuiButtonBase-root:hover {
  color: white !important;
  text-decoration: underline;
}

.optionListContainer li:hover{
  cursor: pointer !important;
  color: #fff !important;
  text-decoration: none !important;
  background-color: #01D298 !important;
  }

  /* .MuiSvgIcon-root{
    color:#01D298 !important;
  } */


  header {
   
    position: fixed;
    width: 100%;
    z-index: 999;
    transition: .3s;
  }
  header.sticky {
    background: #FFF;
    padding: 20px 0;
    box-shadow: 0 0 10px rgba(0,0,0,.3);
  }
  
  #banner {
    position: relative;
    background-size: cover;
    background-position: 150px bottom;
    background-repeat: no-repeat;
  }
  .banner-contents {
    max-width: 420px;
    padding: 280px 0;
  }
  .banner-image {
    position: absolute;
    right: 0;
    top: 23%;
    width: 100%;
    text-align: right;
  }
  .banner-contents {
    max-width: 420px;
  }
  .banner-contents img {
    display: block;
    margin-bottom: 20px;
  }
  .banner-image img {
    width: 1000px;
  }
  
  #receiving {
    padding: 150px 0 0 0;
    position: relative;
  }
  .receiving-contents img {
    margin-bottom: 15px;
  }
  h2, h4 {
    color: #1f2034;
    font-family: ProximaNova-extra-bold;
  }
  .receiving-contents h4 {
    font-family: ProximaNova-bold;
    margin: 15px 0 23px 0;
    max-width: 400px;
  }
  .receiving-contents h4 strong {
    display: ;
    color: #0AE1A5;
  }
  .receiving-contents {
    max-width: 420px;
  }
  .store-image li {
    display: inline-block;
    margin-right: 15px;
  }
  .store-image li:last-child {
    margin-right: 0;
  }
  .store-image {
    margin-top: 25px;
  }
  
  #providing {
    padding-top: 150px;
  }
  #providing .receiving-image {
    padding-top: 100px;
  }
  #providing .receiving-contents {
    max-width: 490px;
  }
  
  footer {
    background: #1F2034;
    padding: 25px 0;
  }
  .footer-menu {
    text-align: right;
  }
  .footer-menu li a {
    display: block;
    color: #FFF;
    font-family: ProximaNova-bold;
  }
  .footer-menu li {
    display: inline-block;
    margin-right: 15px;
    vertical-align: middle;
  }
  .footer-menu li svg {
    color: #02D298;
    font-size: 21px;
    position: relative;
    top: 2px;
  }
  .footer-menu li a:hover {
    color: #02D298;
  }
  
  #work {
    text-align: center;
    padding: 150px 0;
    position: relative;
  }
  #work h4 {
    color: #02D297;
  }
  .section-title p {
    max-width: 800px;
    margin: auto;
    margin-top: 20px;
  }
  .request-content-inner {
    max-width: 415px;
  }
  .needs-content-inner {
    text-align: right;
    padding-top: 125px;
    padding-bottom: 100px;
  }
  .needs-contents {
    text-align: left;
    max-width: 420px;
    margin: auto;
    margin-right: 0;
  }
  .help-bg {
    position: absolute;
    top: 63px;
    left: 47.4%;
    transform: translateX(-50%);
    width: 710px;
  }
  .help-bg img {
    width: 100%;
  }
  #request h4 {
    margin: 20px 0;
  }
  .angle {
    position: absolute;
    right: 19%;
    bottom: 0;
    transform: translateX(15%);
  }
  .dots {
    position: absolute;
    right: 22%;
    bottom: 50px;
    transform: translateX(0%);
  }
  
  .angle-down-inner {
  }
  .angle-down {
    position: absolute;
    bottom: 25px;
    left: 70px;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    text-align: center;
    line-height: 50px;
    background: rgba(255,255,255,.2);
    padding: 7px;
    border: 1px solid rgba(204, 204, 204, 0.53);
    transition: .3s;
  }
  .angle-down-inner {
    width: 100%;
    height: 100%;
    line-height: 48px;
    background: #FFF;
    border-radius: 50%;
    border: 1px solid #CCC;
    transition: .3s;
  }
  .angle-down-inner a {
    display: block;
    color: #02D298;
    transition: .3s;
  }
  .angle-down-inner:hover {
    background: #02D298;
    border: 1px solid rgba(2, 210, 152, .8);
  }
  .angle-down-inner:hover a {
    color: #FFF;
  }
  .angle-down:hover {
    border: 1px solid rgba(2, 210, 152, .6);
  }
  #work .angle {
    right: 23%;
    bottom: 0;
  }
  #work .dots {
    right: 18%;
    bottom: 50px;
  }
  #receiving .angle {
    bottom: -31%;
    right: 20%;
  }
  #receiving .dots {
    right: 19%;
    bottom: -36%;
  }
  .topleft {
    position: absolute;
    left: 0;
    top: -75%;
    transform: translateY(0);
  }
  #receiving .topleft {
    left: 19%;
    top: 32%;
    transform: translateY(0);
  }
  #mobile-menu {
    display: none;
  }
  
  @media(max-width: 1899px){
  #banner {
    background-position: 116px bottom;
  }
  }
  @media(max-width: 1799px){
  #banner {
    background-size: auto;
    background-position: 68px bottom;
  }
  }
  @media(max-width: 1699px){
  #banner {
    background-position: 15px bottom;
  }
  }
  @media(max-width: 1599px){
  #banner {
    background-position: 9% bottom;
  }
  }
  @media(max-width: 1499px){
  #banner {
    background-position: 18% bottom;
  }
  }
  @media(max-width: 1399px){
  #banner {
    background-position: 24% bottom;
  }
  }
  @media(max-width: 1299px){
  #banner {
    background-position: 28% bottom;
  }
  }
  @media(max-width: 1199px){
  #banner {
    background-position: 33% bottom;
  }
  .angle {
    right: 0;
  }
  #receiving {
    padding: 170px 0 0 0;
  }
  #receiving .topleft {
    left: 45px;
    top: 32px;
  }
  
  }
  
  @media(max-width: 991px){
  .angle-down {
    bottom: 90px;
  }
  .store-image li {
    max-width: 150px;
  }
  .help-bg {
    display: none;
  }
  #banner {
    overflow: hidden;
  }
  
  .help-bg {
    display: none;
  }
  .header-inner {
    display: inherit;
  }
  .logo {
    width: 150px;
    text-align: center;
    display: block;
    max-width: 100%;
    margin: auto;
  }
  .collapse-nav {
    text-align: center;
  }
  .menu {
    display: block;
    width: 100%;
    margin: 12px 0;
  }
  .social-menu {
    width: 100%;
    display: block;
    margin: 0;
  }
  .collapse-nav {
    width: 100%;
  }
  .nav-header {
    display: none;
  }
  a {
  }
  .menu li a {
    padding-bottom: 5px;
  }
  .menu li a::after {
    height: 2px;
  }
  .needs-content-inner {
    padding-top: 60px;
  }
  }
  
  
  @media(max-width: 767px){
  .banner-contents {
    padding: 225px 0;
  }
  .collapse-nav {
    background: #02D298;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    z-index: 88;
    padding-top: 110px;
  }
  .menu li {
    display: block;
    text-align: center;
    margin: 0;
  }
  .menu li a {
    margin: 0;
    display: block;
    width: 100%;
  }
  .nav-header {
    display: block;
    right: 0;
  }
  /*hamburger-menu*/
  
  .nav-header {
    position: relative;
    width: 45px;
    height: 45px;
    background: 0 0;
    cursor: pointer;
    float: right;
    z-index: 9999;
  }
  .hamburger {
    position: absolute;
    width: 35px;
    height: 2px;
    background: #02D298;
    top: 50%;
    transform: translate(0%,-50%);
    right: 15px;
  }
  .hamburger:before,
  .hamburger:after{
    content: "";
    position: absolute;
    width: 35px;
    height: 2px;
    background: #02D298;
    transition: .5s;
    left: 0;
  
  }
  .hamburger:before{
    top: -10px;
  
  }
  .hamburger:after{
    top: 10px;
    
  }
  .nav-header.active .hamburger{
    background: rgba(0,0,0,0);
  }
  .nav-header.active .hamburger:before{
    top: 0;
    transform: rotate(45deg);
    background: #FFF;
  }
  .nav-header.active .hamburger:after{
    top: 0;
    transform: rotate(135deg);
    background: #FFF;
  
  }
  .collapse-nav{
    display: none;
  }
  
  .menu li {
    text-align: left;
  }
  .social-menu {
    padding-top: 100px;
  }
  .menu li a {
    padding: 7px 20px;
    color: #fff;
  }
  .menu li a::after {
  
    display: none;
  }
  .menu li a:hover {
    background: #FFF;
    color: #02D298 !important;
    padding-left: 30px;
  }
  .menu {
    margin: 0;
  }
  
  .nav-header {
    position: absolute;
    top: -6px;
    right: 2px;
  }
  .logo {
    margin-left: 0;
  }
  #banner {
    background-position: 20% bottom;
  }
  #receiving {
    padding: 119px 0 0 0;
    text-align: center;
  }
  .store-image li {
     max-width: 200px; 
  }
  .receiving-contents {
    margin-top: 30px;
  }
  .topleft {
    left: 25px;
  }
  .footer-logo {
    text-align: center;
    margin-bottom: 10px;
  }

  
  .footer-menu {
    text-align: center;
  }
  .menu li a {
  }
  .collapse-nav li.custom-down:focus .collapse-nav{
    display: none;
  }
  #desktop-menu {
    display: none !important;
  }

  .MuiIconButton-colorInherit{
    color: black !important;
  }

  }